<template>
  <div>
    <v-toolbar color="#8950FC">
      <v-toolbar-title>
        <h1 class="text-white">{{ pageDescription }}</h1>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-card>
      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
        </div>
        <v-container>
          <v-row wrap>
            <v-col cols="12">
              <v-card elevation="24" outlined shaped>
                <!--begin::Card-->
                <member-details :records="rows"></member-details>
                <!--end::Card-->
              </v-card>
            </v-col>
          </v-row>

          <!--begin::Row-->
          <v-row wrap>
            <v-col cols="12" sm="6" lg="6" md="6">
              <v-card elevation="24" outlined shaped>
                <v-card-title>Address</v-card-title>
                <member-address :records="address"></member-address>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" lg="6" md="6">
              <v-card elevation="24" outlined shaped>
                <v-card-title>JCI Career</v-card-title>
                <member-carrer :records="MemberId"></member-carrer>
              </v-card>
              <br />
              <v-card
                elevation="24"
                outlined
                shaped
                v-if="RoleId == 1 || RoleId == 2 || RoleId == 4"
              >
                <v-card-title>Contribution Paid details</v-card-title>
                <member-dues :records="dues"></member-dues>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closePrompt">Close</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import MemberDetails from "@/view/pages/erp/members/member-individual/MemberDetails.vue";
import MemberAddress from "@/view/pages/erp/members/member-individual/MemberAddress.vue";
import MemberCarrer from "@/view/pages/erp/members/member-individual/MemberCarrer.vue";
import MemberDues from "@/view/pages/erp/members/member-individual/MemberDues.vue";

export default {
  name: "custom-page",
  props: {
    previewRecordPrompt: {
      type: Boolean,
      required: true
    },
    recordIndex: {
      type: Number,
      required: true
    },
    pageDescription: {
      type: String
    }
  },
  components: {
    MemberDetails,
    MemberAddress,
    MemberCarrer,
    MemberDues
  },
  computed: {
    ...mapGetters(["currentUserPhoto"])
  },
  data() {
    return {
      LoadingFlag: false,
      ProgessStart: 0,
      uploadPercentage: 0,

      SuccessMessageTxtFlag: 0,
      SuccessMessageTxt: "",

      ErrorMessageTxtFlag: 0,
      ErrorMessageTxt: "",

      totalActiveAwards: 0,
      totalPendingAwards: 0,

      ResultFlag: 0,

      tab: null,
      MemberId: "",
      address: {},
      contact: [],
      designation: [],
      dues: [],

      rows: [],
      RoleId: ""
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Profile 3" }]);
  },
  watch: {
    rows: function() {
      console.log("watch rows");
      var rows = this.rows;
      var n1 = Object.keys(rows).length;
      console.log("n1=" + n1 + ", rows=" + JSON.stringify(rows));
      if (n1 > 0) {
        this.ResultFlag = 1;
        this.address = rows.Address[0];
        this.contact = rows.Contact;
        this.designation = rows.OfficesHeld;
        this.dues = rows.Dues;
      }
    }
  },
  methods: {
    backPage() {
      this.$router.go(-1);
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog");
    },
    resetMessageTxt() {
      console.log("resetMessageTxt called");
      this.ErrorMessageTxt = 0;
      this.ErrorMessageTxtFlag = "";
      this.SuccessMessageTxtFlag = 0;
      this.SuccessMessageTxt = "";
      this.totalActiveAwards = 0;
      this.totalPendingAwards = 0;
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.getTableRecords();
    },
    getTableRecords() {
      console.log("getTableRecords called");

      this.resetMessageTxt();
      this.LoadingFlag = true;

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/members/show";
      var upload = {
        UserInterface: 2,
        MemberId: this.MemberId
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        },
        onUploadProgress: function(progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this)
      })
        .then(function(response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.LoadingFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records[0];
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          if (flag == 1) {
            thisIns.SuccessMessageTxtFlag = 1;
            thisIns.SuccessMessageTxt = output;
            thisIns.rows = records;
          } else {
            thisIns.ErrorMessageTxtFlag = 1;
            thisIns.ErrorMessageTxt = output;
          }
        })
        .catch(function(error) {
          thisIns.LoadingFlag = false;
          console.log("error=" + error);
          thisIns.toast("error", output);
        });
    }
  },
  beforeMount() {
    var MemberId = this.recordIndex;
    console.log("MemberId=" + MemberId);

    this.MemberId = MemberId;

    var token = this.$session.get("token");
    token = token == (null || undefined) ? 0 : token;

    var RoleId = this.$session.get("RoleId");
    RoleId = RoleId == (null || undefined) ? 0 : RoleId;

    console.log("token=" + token + ", RoleId=" + RoleId);
    this.RoleId = RoleId;
    this.refreshPageData();
    // this.$router.push("/access-denied");
  }
};
</script>
