<template>
  <div id="staff-list">
    <v-dialog v-model="editRecordPrompt" persistent max-width="85%">
      <member-edit
        pageDescription="Edit Member Details"
        :editRecordPrompt="editRecordPrompt"
        @hideEditRecordPrompt="hideEditRecordPrompt"
        :recordIndex="recordToEdit"
        v-if="editRecordPrompt"
      ></member-edit>
    </v-dialog>
    <v-dialog v-model="previewRecordPrompt" persistent max-width="85%">
      <member-details
        pageDescription="Member Details"
        :previewRecordPrompt="previewRecordPrompt"
        @hideDialog="hidePreviewRecordPrompt"
        :recordIndex="recordToEdit"
        v-if="previewRecordPrompt"
      ></member-details>
    </v-dialog>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-row wrap>
          <v-col cols="12" md="12" align="center">
            <v-btn
              elevation="30"
              shaped
              tile
              small
              color="#9370d8"
              class="font-size-h6 white--text"
              @click.prevent="refreshPageData"
              >Refresh</v-btn
            >
          </v-col>
        </v-row>
        <v-container class="py-0">
          <v-form ref="form1" v-model="valid1" lazy-validation>
            <v-row wrap>
              <v-col cols="12" md="4">
                <label>
                  <h6><span class="text-danger">*</span> Zone</h6>
                </label>
                <v-autocomplete
                  @change="getLomCodeOptions"
                  :reduce="(option) => option.value"
                  :loading="ZoneCodeOptionsLoading"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  v-model="ZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <label>
                  <h6><span class="text-danger">*</span> LOM</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :rules="LomCodeRules"
                  :loading="LomCodeOptionsLoading"
                  :items="LomCodeOptions"
                  v-model="LomCode"
                  dense
                  clearable
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="searchForm"
                  :loading="SearchFlag"
                  color="#8950FC"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="
                    btn btn-primary
                    font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                    white--text
                  "
                  >Search</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container class="py-0" v-if="tableData1.length == 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h4>No records found.</h4>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="py-0" v-if="tableData1.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3 class="text-primary">
                {{ tableData1.length }} members found.
              </h3>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                v-model="selected"
                :search="search"
                :item-key="tableOptions1.ItemKey"
                :single-select="tableOptions1.SingleSelectFlag"
                :headers="tableColumns1"
                :items="tableData1"
                :items-per-page="tableOptions1.ItemsPerPage"
                :footer-props="tableOptions1.FooterProps"
              >
                <template v-slot:item.actions="{ item }">
                  <img width="100" height="100" :src="item.ProfilePic" v-if="ProfilePic != ''" />
                  <br/>
                  <v-tooltip bottom v-if="item.PreviewFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="primary"
                        @click="previewData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-file-search</v-icon
                      >
                    </template>
                    <span>Preview</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.EditFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="warning"
                        @click="editAlert(item, e)"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-file-document-edit</v-icon
                      >
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <br />
        <br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import MemberEdit from "@/view/pages/members/MemberEdit.vue";
import MemberDetails from "@/view/pages/erp/members/member-individual/MemberIndividualDialog.vue";

export default {
  mixins: [common],
  components: {
    MemberEdit,
    MemberDetails,
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ResultFlag: false,

      editRecordPrompt: false,
      recordToEdit: "",

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      LomCodeRules: [(v) => !!v || "LOM is required"],
      LomCode: "",
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      tableData1: [],
      tableColumns1: [],
      tableOptions1: [],
      selected: [],
      search: "",
      CurrentYearId: 0,

      previewRecordPrompt: false,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  mounted() {},
  watch: {
    SearchId: function () {
      console.log("watch SearchId");
      this.rows = [];
    },
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        this.getZoneCodeOptions();
      }
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
      this.LoadingFlag = false;
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions");
      this.LomCodeOptionsLoading = false;
      this.LoadingFlag = false;
    },
  },
  methods: {
    validate() {
      if (this.$refs.form1.validate()) {
        this.snackbar = true;
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "jci_india_members",
        Action: "report_1",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    searchForm() {
      console.log("searchForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        var message = "";

        var ZoneCode = this.ZoneCode;
        console.log({ ZoneCode });

        var LomCode = this.LomCode;
        console.log({ LomCode });

        if (LomCode != "" && ZoneCode != "") {
          this.LoadingFlag = true;
          this.SearchFlag = true;
          this.tableData1 = [];

          var server_url = companyConfig.apiURL;
          var token = this.$session.get("token");
          token = token == (null || undefined) ? 0 : token;
          var add_url = server_url + "api/members";
          var upload = {
            UserInterface: 1,
            additional: {
              LomCode: this.LomCode,
            },
          };
          console.log("upload=" + JSON.stringify(upload));
          console.log(
            "server_url=" +
              server_url +
              ", token=" +
              token +
              ", add_url=" +
              add_url
          );

          const thisIns = this;
          var output = "";
          var records = {};
          var successTxt = "";
          var errorTxt = "";
          var flag = 0;

          this.$http({
            url: add_url,
            method: "POST",
            data: upload,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }.bind(this),
          })
            .then(function (response) {
              console.log("response=" + JSON.stringify(response));

              // thisIns.pageLoadingOff()
              thisIns.LoadingFlag = false;
              thisIns.SearchFlag = false;

              output = response.data.output;
              flag = response.data.flag;
              output = response.data.output;
              records = response.data.records;
              console.log("output=" + output + ", flag=" + flag);
              successTxt = response.data.success;
              errorTxt = response.data.error;
              console.log(
                "successTxt=" + successTxt + ", errorTxt=" + errorTxt
              );

              console.log({ records });

              var table_content = response.data.table_content;
              var options = table_content.TableOptions;
              console.log({ options });
              thisIns.tableOptions1 = options;

              if (flag == 1) {
                thisIns.tableColumns1 = table_content.TableHeader;
                thisIns.tableData1 = records;
                thisIns.toast("success", output);
              } else {
                thisIns.toast("error", output);
              }
            })
            .catch(function (error) {
              console.log("error=" + error);
              thisIns.LoadingFlag = false;
              thisIns.SearchFlag = false;
              thisIns.toast("error", output);
            });
        } else {
          if (YearCode == "") {
            message += "Year should not be empty. ";
          }
          if (LomCode == "") {
            message += "Lom should not be empty. ";
          }
          this.toast("error", message);
        }
      }
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        this.ZoneCodeOptionsLoading = true;
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      var YearCode = this.JciYearCode;
      var ZoneCode = this.ZoneCode;
      console.log("YearCode=" + YearCode + ", ZoneCode=" + ZoneCode);
      if (YearCode != "" && ZoneCode != "") {
        this.LomCodeOptionsLoading = true;
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: YearCode,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    previewData(tr) {
      console.log("previewData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var id = tr.MemberId;
        console.log("id=" + id);
        this.recordToEdit = id;
        this.previewRecordPrompt = true;
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
    },
    editAlert(tr) {
      console.log("editAlert called");
      console.log({ tr });
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var id = tr.MemberId;
        console.log({ id });
        this.recordToEdit = id;
        this.editRecordPrompt = true;
      } else {
        var message = "Kindly select one record to edit";
        this.errorMessage(message);
      }
    },
    hideEditRecordPrompt() {
      console.log("hideEditRecordPrompt called");
      this.editRecordPrompt = false;
      this.searchForm();
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#staff-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
}
</style>